<template>
  <v-card v-if="request" :loading="isLoading">
    <v-card-title>
      <span style="font-size: 1.25rem; font-weight: 500;">依頼内容の要約</span>
      <div class="ml-1">
        <popup-hint>OpenAIの生成AIモデルを活用するため、要約結果が正確ではない場合があります。<br>また、依頼内容および要約結果はOpenAI社のサービス開発や改善には利用されません。</popup-hint>
      </div>
      <v-spacer></v-spacer>
      <template v-if="canRegenerateRequestSummary">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on" @click="regenerateRequestSummary" :disabled="!canRegenerateRequestSummary" rounded small style="text-transform: none;"><v-icon class="mr-1" small>mdi-creation-outline</v-icon>AI要約 (β)</v-btn>
          </template>
          <span>生成AIで依頼内容を要約します。</span>
        </v-tooltip>
      </template>
    </v-card-title>
    <div v-show="request.request_summary">
      <v-divider class="mx-4"></v-divider>
      <v-card-text v-html="request.request_summary"></v-card-text>
    </div>
  </v-card>
</template>

<script>
import axios from '@/plugins/axios'
import PopupHint from '@/components/PopupHint.vue'

export default {
  name: 'RequestSummary',
  components: {
    PopupHint
  },
  props: {
    request: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    isLoading: false
  }),
  methods: {
    regenerateRequestSummary: function () {
      const self = this
      self.isLoading = true
      const formData = new FormData()
      formData.append('_method', 'PUT')
      axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/request/${self.request.id}/request_summary`, formData)
        .then(() => {
          self.$emit('change')
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          window.setTimeout(() => {
            self.isLoading = false
          }, 2000)
        })
    }
  },
  computed: {
    canRegenerateRequestSummary () {
      if (this.isLoading === true) {
        return false
      }
      if (this.request.request_summary_update_at !== null) {
        return false
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card__text {
  white-space: normal;
  font-size: 1rem;
  line-height: 1.75;
}
</style>
